import React from 'react'
import Helmet from 'react-helmet'
import { Link, withPrefix } from 'gatsby'
import Img from 'gatsby-image'

import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'
import '../css/bootstrap.min.css'
import '../css/jquery-ui.css'
import '../css/animate.css'
import '../css/fontawesome-all.min.css'
import '../css/magnific-popup.css'
import '../css/style.css'
import '../css/stellarnav.min.css'
import '../css/responsive.css'

import logo from '../img/instar_logo_gradient_white_text.svg'

class Shareholders extends React.Component {
  render() {
    const { location, data } = this.props
    return (
      <Layout location={location}>
        <Helmet>
          <script async src={withPrefix('jquery-3.3.1.min.js')} />
          <script async src={withPrefix('jquery-ui.js')} />
          <script async src={withPrefix('wow.min.js')} type="text/javascript" />
          <script async src={withPrefix('bootstrap.min.js')} />
          {/*<script async src={withPrefix('countdown.js')} type="text/javascript"/>*/}
          <script async src={withPrefix('html5shiv.js')} />
          <script async src={withPrefix('html5shiv-printshiv.js')} />
          {/*<script async src={withPrefix('jquery.counterup.min.js')} type="text/javascript" />*/}
          <script async src={withPrefix('jquery.magnific-popup.min.js')} />
          <script async src={withPrefix('jquery.scrollUp.js')} />
          <script async src={withPrefix('jquery.waypoints.min.js')} />
          {/*<script async src={withPrefix('owl.carousel.min.js')} type="text/javascript" />*/}
          <script async src={withPrefix('popper.min.js')} />
          <script async src={withPrefix('stellarnav.min.js')} />
          <script async src={withPrefix('theme.js')} type="text/javascript" />
        </Helmet>
        <Meta site={siteMetadata} title="Shareholders" />
        <div>
          {/*<div id="preloader"></div>*/}
          <header>
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-sm-6 col-6">
                  <div className="logo">
                    <Link to="/" className="smoothscroll">
                      <img src={logo} style={{ maxHeight: '60px' }} />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-8 col-sm-12 col-12 align-self-center">
                  <div className="main-menu">
                    <div className="stellarnav">
                      <nav className="menu-list">
                        <ul>
                          <li>
                            <Link to="#_centers">Data centers</Link>
                          </li>
                          <li>
                            <Link to="#_roadmap">roadmap</Link>
                          </li>
                          <li>
                            <Link to="#_team" className="smoothscroll">
                              team
                            </Link>
                          </li>
                          <li>
                            <Link to="#_questions" className="smoothscroll">
                              FAQ
                            </Link>
                          </li>
                          <li>
                            <a href="#" className="smoothscroll">
                              Investor Relations
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 d-none d-lg-block">
                  <div className="ht-buy-token">
                    <Link to="#_eu" className="smoothscroll">
                      <img
                        src="../img/ue_logo.png"
                        style={{ maxHeight: '80px' }}
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </header>

          <section className="shareholders-area" id="_shareholders">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 d-flex align-items-center">
                  <div className="shareholders-content">
                    <h3 className="title">Relacje inwestorskie</h3>
                    <h3 className="title-2">O Spółce</h3>
                    <p className="text">
                      InStar Energy Lab S.A. <br />
                      ul. Sienna 86/47 00-815 Warszawa
                      <br />
                      NIP: 7010638914, REGON: 366020312
                      <br />
                      KRS: 0000803322 Sąd Rejonowy Warszawa XII Wydział
                      Gospodarczy
                    </p>
                    <h3 className="title-2">Akcjonariat</h3>
                    <p className="text">
                      Kapitał zakładowy: 100.000 zł
                      <br />
                      Kapitał opłacony: 100.000 zł
                    </p>
                    <h3 className="title-2">Dematerializacja akcji</h3>
                    <p className="text">
                      Wskutek wejścia w życie ustawy dnia 30 sierpnia 2019 r. o
                      zmianie ustawy - Kodeks spółek handlowych oraz niektórych
                      innych ustaw (Dz.U. z 2019 r. poz. 1798) najpóźniej do
                      31.12.2020 r. należy złożyć w spółce wszystkie papierowe
                      dokumenty własności akcji aby mogły być przekształcone w
                      formę elektroniczną. Moc obowiązująca dokumentów akcji
                      wydanych przez spółkę wygasa z mocy prawa z dniem 1
                      stycznia 2021 r.
                    </p>
                    <p className="text">
                      Złożenie dokumentów akcji w spółce odbywa się za pisemnym
                      pokwitowaniem wydanym akcjonariuszowi.
                    </p>
                    <h3 className="title-2">Rejestr akcjonariuszy</h3>
                    <h3 className="title-2">Walne Zgromadzenia / ogłoszenia</h3>
                    <p className="text">
                      Zarząd InStar Energy Lab S.A. z siedzibą w Warszawie
                      (00-131), ul. Grzybowska 4/106., numer KRS 0000803322,
                      zawiadamia, że dnia 23.12.2019 odbyło się Nadzwyczajne
                      Walne Zgromadzenie InStar Energy Lab, na którym podjęto
                      uchwały stanowiące podstawę do następujących wpisów w KRS:
                      rezygnacja Pana Lecha Wilczyńskiego z uczestnictwa w
                      Radzie Nadzorczej Instar Energy Lab S.A. powołanie na
                      członka Rady Nadzorczej Instar Energy Lab S.A. Pana
                      Krzysztofa Piecha,
                    </p>
                    <p className="text">
                      Zarząd InStar Energy Lab S.A. z siedzibą w Warszawie
                      (00-131), ul. Grzybowska 4/106, numer KRS 0000803322,
                      zawiadamia, że dnia 23.12.2019 odbyło się Posiedzenie Rady
                      Nadzorczej InStar Energy Lab, na którym podjęto uchwały
                      stanowiące podstawę do następujących wpisów w KRS:
                      odwołanie Pana Kamila Puszczyńskiego z funkcji Prezesa
                      Zarządu Instar Energy Lab S.A. powołanie na Prezesa
                      Zarządu Instar Energy Lab S.A. Pana Lecha Wilczyńskiego
                    </p>
                    <p className="text">
                      Zarząd InStar Energy Lab S.A. z siedzibą w Warszawie
                      (00-131), ul. Grzybowska 4/106, numer KRS 0000803322,
                      zawiadamia, że dnia 23.12.2019 ustanowił Pana Kamila
                      Puszczyńskiego prokurentem (prokura samoistna) InStar
                      Energy Lab S.A.
                    </p>
                    <br />
                    <p className="text" style={{ fontWeight: '600' }}>
                      Zarząd Instar Energy Lab S.A. z siedzibą w Warszawie wzywa
                      wszystkich akcjonariuszy Spółki do złożenia w Spółce
                      dokumentów akcji w celu ich dematerializacji, w terminie
                      do dnia 31.12.2020 r. Dokumenty akcji należy składać w
                      siedzibie Spółki, tj. ul. Grzybowska 4 lok. 106, 00-131
                      Warszawa, od poniedziałku do piątku w godzinach 10-16.
                      Niniejsze wezwanie jest pierwszym z pięciu wezwań.
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-lg-12 col-md-12 col-12 d-flex justify-content-between">
                  <img
                    src="../img/FE_IR_rgb-1.jpg"
                    alt=""
                    className="align-self-center"
                  />
                </div>
              </div>
            </div>
          </section>

          <footer>
            <div className="footer-top-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-12">
                    <div className="footer-widget">
                      <h2>InStar Energy Lab S.A.</h2>
                      <p className="text">
                        ul. Grzybowska 4/106 00-131 Warszawa
                      </p>

                      <h3>Company details</h3>
                      <p className="text">
                        NIP: 7010638914, REGON: 366020312
                        <br />
                        KRS: 0000803322 Sąd Rejonowy Warszawa XII Wydział
                        Gospodarczy
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-3 col-12"></div>

                  <div className="col-lg-4 col-md-5 col-12">
                    <div className="footer-widget">
                      <h3 className="title-2">Follow Us</h3>
                      <div className="row">
                        <div className="col-lg-6 col-12">
                          <ul className="social">
                            <li>
                              <a href="https://www.facebook.com/InStarEnergyLab/">
                                <span className="ico">
                                  <i className="fab fa-facebook-f"></i>
                                </span>{' '}
                                Facebook
                              </a>
                            </li>
                            <li>
                              <a href="https://www.linkedin.com/company/instar-energy-lab-s-a">
                                <span className="ico">
                                  <i className="fab fa-linkedin-in"></i>
                                </span>{' '}
                                Linkedin
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </Layout>
    )
  }
}

export default Shareholders
